<template>
    <span> Settings page</span>
</template>
<script>
import { defineComponent } from '@vue/composition-api'


export default defineComponent({
    methods:{
    },
    setup() {
        
    },
    beforeMount(){
  
    }
})
</script>
